import React from 'react'
export default function AlertHeader (props){
    
    return(
        <div className="AlertHeaderText">
            <strong className="TextAlertHeader">
            {props.pagina==="IT"?
            <>Workflow della Prevenzione</>
            :<>Prevention Workflow</>}
            </strong>
        </div>
    )
}
