import React from 'react';
import { Link } from "gatsby"
import headerPicsIta from '../../assets/LogoIta.png'
import headerPicsEng from '../../assets/LogoEng.png'
import headerPics from '../../assets/Logo.png'
import {Row} from 'react-bootstrap'
import AlertHeader from './AlertHeader'
import './stile.css'

export default function NavigationBar(props){

  return(
      <div>
        <Row className="SfondoNavbar" noGutters>
            <div className="ColHeaderImg">
                    <img 
                        src={headerPics}
                        className="headerPic"
                        alt="Ministero della Salute"
                    />
            </div>
            <div className="ColHeader">
                        {props.page==="EN"?                            
                        <Link to={props.sezione==='0' ? "/" : props.sezione==='1' ? "/AccessoAlSistemaWorkflow" : "/Documentazione"} >
                                <img 
                                src="https://www.bandiere-mondo.it/data/flags/w702/it.webp"
                                className="bandiera"
                                alt="IT"
                                />
                            </Link>
                            :
                            <Link to={props.sezione==='0' ? "/EN" : props.sezione==='1' ? "/AccessWorkflowSystem" : "/Documentation"}>
                                <img 
                                src="https://www.bandiere-mondo.it/data/flags/w702/gb.webp"
                                alt="EN"
                                className="bandiera"
                                />
                            </Link>
                            }
            </div>
        </Row>
        <AlertHeader pagina={props.page}/>
    </div>
    )
}